.footer-header-left{
    margin-left: 15rem;
    position: absolute;
    padding: 0rem;
  }

.footer-list-text{
    font-size: 1.17em;
    color: rgba(0, 0, 0, 0.9);
}

.footer-text{
    padding-top: 0px;
}

.footer-icons{
    height: 32px !important;
}

.footer-icons-margin{
    margin-right: 1rem;
}

.footer-pictures{
    padding-left: 60px;
}

@media screen and (max-width: 980px) {
.reverse-order{
   display: flex; flex-flow: column-reverse;
}
.justify-small-reso{
    justify-content: center;
}
.col-2{
    flex: 83.33333%;
    max-width: 83.333333%;
}
.align-text{
    text-align: center;
}
.w-to-100{
    width: 100% !important;
}
}


.footer-list-text-pictures{
    font-size: 1.17em;
    color: rgba(0, 0, 0, 0.9);
    padding: 0rem;
}

.navbar-footer{
    padding-left: 0rem;
    padding-right: 0rem;
    position: initial;
}

.grid-first-footer{
    padding-left: 0rem;
    padding-right: 0rem;
}

.text-grid{
    padding-left: 0rem;
}