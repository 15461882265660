
@import '@angular/cdk/overlay-prebuilt.css';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$games-primary: mat-palette($mat-indigo);
$games-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$games-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$games-theme: mat-light-theme(
  (
    color: (
      primary: $games-primary,
      accent: $games-accent,
      warn: $games-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($games-theme);



/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
/* Importing Bootstrap SCSS file. */
//@import "~bootstrap/scss/bootstrap";
@import "./sass/header";
@import "./sass/footer";

mat-accordion.mat-accordion.ng-star-inserted mat-expansion-panel div .mat-expansion-panel-body{
  padding: 0px 0px 0px !important
}



/*
@include media-breakpoint-up(xl){
  .container{
    max-width: calc(1200px + 10%) !important;
  }
}
*/

.custom-width {
  min-width: 50vw !important;
  min-height: 30vw !important;
}

.hide_scrollbar {
  overflow: hidden !important;
}

.image-blog{
  max-height: 400px !important;
}

.blog-title-height{
  height: 75px
}

.wrap {
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@media (max-width: 599px) {
  .mat-form-field:not(.mat-form-field-has-label) .mat-form-field-infix {
    border-top-width: 0;
  }

  .ngx-pagination {
    padding-left: 0px !important;
  }
}

.chat-background-sender{
  background-color: #F5F5F5 !important;
}

.chat-border-sender{
  border: 1px solid #F5F5F5 !important
}

.chat-background-recipient{
  background-color: #369ad5 !important;
}

.chat-border-recipient{
  border: 1px solid #369ad5 !important
}

.chat-text-recipient{
  color: white !important
}

.uretao-border-right{
  border-right: 1px solid #369ad5 !important
}

.uretao-border-bottom{
  border-bottom: 1px solid #369ad5 !important
}

.highlight {
  color: #369ad5;
  font-weight: 500;
}

.button {
  background-color: #d66b40 !important;
  color: white !important;
}

.hyperlink-button{
  color: #d66b40 !important;
  text-decoration: underline #d66b40 !important;
}

.anti-button {
  background-color: #494d70 !important;
  color: white !important;
}

.button-text {
  color: #d66b40 !important;
}

.anti-button-text {
  color: #494d70 !important;
}

.anti-button-flat {
  color: #494d70 !important;
}

.icon {
  color: #d66b40 !important;
}

@media (max-width: 930px) {
  .button-sm {
    background-color: #d66b40 !important;
    color: white !important;
    width: 100% !important;
    border-radius: 0px !important;
  }

  .anti-button {
    background-color: #494d70 !important;
    color: white !important;
    width: 100% !important;
    border-radius: 0px !important;
  }
}

@media (min-width: 930px) {
  .button-sm {
    background-color: #d66b40 !important;
    color: white !important;
    width: 25% !important;
  }
}

@media (min-width: 1200px) {
  .container-lg {
    max-width: calc(1000px + 10%) !important;
  }
}

@media (min-width: 1500px) {
  .container-lg {
    max-width: calc(1200px + 10%) !important;
  }
  //better spacing between header components on large screen
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (min-width: 2000px) {
  .container-lg {
    max-width: calc(1600px + 10%) !important;
  }
}

/*
::ng-deep .mat-menu-panel.test{
  overflow: hidden !important;
}
*/

.customize+* .mat-menu-panel {
  overflow: hidden !important;
  max-width: none !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.angular-editor-toolbar {
  background-color: #ebf6ff !important;
}

app-auction-overview-list-renew-mobile .mat-pseudo-checkbox {
  display: none !important;
}
