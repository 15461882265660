.navbar-header-left{
    margin-left: 15rem;
  }
  
  .navbar-header-right{
    margin-right: 15rem;
  }

  .field-background{
    background-color: white !important;
  }

  .header-text{
    font-weight: 600 !important;
    color: #369AD5 !important;
  }

  .header-dropdown-subitem{
    font-weight: 500 !important;
    color: #369AD5 !important;
  }
  
  .header-item{
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.17em;
  }
  
  .header-titel{
    font-size: 2em;
  }
  
  .inpageHeader{
    padding-bottom: 3rem;
    padding-top: 3rem;
    }

.logo-size{
  height: 100px !important;
}